/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../helpers'
import {baseUrl} from '../../../../../Helpers/Requests'

type Props = {
  className: string
  plan: any
  config: object | null
  loaded?: any
}

const EngageWidget10 = ({className, plan, config, loaded}: Props) => (
  <div className={`card card-flush ${className} ${loaded ? '' : 'overlay overlay-block'}`}>
    <div
      className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
      style={{
        backgroundPosition: '100% 50%',
        backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
      }}
    >
      <div className='overlay-wrapper'>
        {loaded ? (
          <>
            <div className='mb-10'>
              {(plan == null && (
                <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
                  <span className='me-2'>
                    Você não tem nenhuma assinatura ativa
                    <br />
                    <span className='position-relative d-inline-block text-danger'>
                      <Link
                        to='/planos'
                        className='text-danger opacity-75-hover'
                      >
                        Escolha seu plano
                      </Link>

                      <span className='position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100'></span>
                    </span>
                  </span>
                </div>
              )) || (
                <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
                  <span className='me-2'>
                    Sua assinatura está&nbsp;
                    <span className='position-relative d-inline-block text-danger'>
                      <Link
                        to='/minha-assinatura'
                        className='text-success
                opacity-75-hover'
                      >
                        Ativa!
                      </Link>

                      <span className='position-absolute opacity-15 bottom-0 start-0 border-4 border-primary border-bottom w-100'></span>
                    </span>
                  </span>
                </div>
              )}

              {plan != null && (
                <>
                  <div className='d-flex justify-content-center w-100'>
                    <div className='dashboard-subscription-container d-flex align-items-center justify-content-center flex-column'>
                      <Link
                        to='/minha-assinatura'
                        className='text-warning opacity-75-hover fs-3hx fw-bold notranslate'
                      >
                        {plan.nome}
                      </Link>

                      <img
                        className='mx-auto h-150px h-lg-200px  theme-dark-show'
                        src={baseUrl(plan.image)}
                        alt=''
                      />

                      <div className='text-center pt-2'>
                        <Link to='/planos'>Faça upgrade do seu plano</Link>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {plan == null ? (
              <div className='dashboard-img-container'>
                <img
                  className='mx-auto h-80px h-lg-250px  theme-light-show'
                  src={toAbsoluteUrl('/media/illustrations/sigma-1/21.png')}
                  alt=''
                />
                <img
                  className='mx-auto h-80px h-lg-250px  theme-dark-show'
                  src={toAbsoluteUrl('/media/illustrations/sigma-1/21-dark.png')}
                  alt=''
                />
              </div>
            ) : (
              <div className='dashboard-img-container'>
                <img
                  className='mx-auto h-80px h-lg-250px  theme-light-show'
                  src={toAbsoluteUrl('/media/illustrations/sigma-1/8.png')}
                  alt=''
                />
                <img
                  className='mx-auto h-80px h-lg-250px  theme-dark-show'
                  src={toAbsoluteUrl('/media/illustrations/sigma-1/8-dark.png')}
                  alt=''
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className='mb-10'>
              <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
                <span className='me-2'>Buscando Assinatura...</span>
              </div>
            </div>

            <div className='dashboard-img-container'>
              <img
                className='mx-auto h-80px h-lg-250px  theme-light-show'
                src={toAbsoluteUrl('/media/illustrations/sigma-1/9.png')}
                alt=''
              />
              <img
                className='mx-auto h-80px h-lg-250px  theme-dark-show'
                src={toAbsoluteUrl('/media/illustrations/sigma-1/9-dark.png')}
                alt=''
              />
            </div>
          </>
        )}
      </div>
    </div>
    {!loaded && (
      <div className='overlay-layer rounded bg-dark bg-opacity-5'>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Carregando...</span>
        </div>
      </div>
    )}
  </div>
)
export {EngageWidget10}
